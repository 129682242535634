<template>
  <div>
    <CHeader
      class="bg-sidebar-color sticky-top border-0"
      id="header"
      fixed
    >
      <CNavbar class="w-100" expandable="xl">
        <CNavbarBrand to="/tfw" class="nav-logo pt-0">
          <img
            src="/img/logo.svg"
            width="120"
            sizes="120px"
          />
        </CNavbarBrand>
        <CToggler
          class="ml-auto toggler-mobile"
          :class="isStmUrl ? 'd-none' : ''"
          in-navbar
          @click="collapsed = !collapsed"
        />
        <CCollapse :show="collapsed" navbar v-if="!isStmUrl">
          <CNavbarNav v-if="!isMobile" class="ml-auto navbar-bg">
            <CNavItem class="nav-font">
              <CDropdown
                inNav
                class="nav-dropdown"
                placement="bottom-end"
                add-menu-classes="pt-0"
                addTogglerClasses="pt-0"
                ref="menu_mkt_place"
                @mouseover.native="showHide('menu_mkt_place', true)"
                @mouseleave.native="showHide('menu_mkt_place', false)"
              >
                <template #toggler-content>
                  <div>
                    <span>Marketplace</span>
                  </div>
                </template>
                <CDropdownItem to="/tfw/course">
                <em class="fas fa-briefcase" style="margin-right: 10px"></em>
                  Training Courses
                </CDropdownItem>
                <CDropdownItem to="/tfw/partner/profile">
                <em class="fas fa-briefcase" style="margin-right: 10px"></em>
                  Partner Profiles
                </CDropdownItem>
                <CDropdownItem to="/tfw/placements">
                <em class="fas fa-briefcase" style="margin-right: 10px"></em>
                  Placements
                </CDropdownItem>
              </CDropdown>
            </CNavItem>
            <CNavItem
              class="nav-font"
              to="/tfw"
              @click.native="scrollMeTo('tfw-section-6')"
              >Employers</CNavItem
            >
            <CNavItem class="nav-font" to="/tfw/login">Login</CNavItem>
            <CNavItem class="nav-font" to="/tfw/registration/candidate"
              >Register Here</CNavItem
            >
          </CNavbarNav>
          <CNavbarNav
            v-else
            @click="collapsed = !collapsed"
            class="ml-auto navbar-bg"
          >
            <CNavItem
              class="nav-font"
              to="/tfw"
              @click.native="scrollMeTo('tfw-section-6')"
              >Employers</CNavItem
            >
            <CNavItem class="nav-font" to="/tfw/login">Login</CNavItem>
            <CNavItem class="nav-font" to="/tfw/registration/candidate"
              >Register Here</CNavItem
            >
          </CNavbarNav>
        </CCollapse>
      </CNavbar>
      <CLink v-if="!isStmUrl" to="/tfw">
        <h3 class="home-icon">
          <i class="fa-solid fa-house-chimney"></i>
        </h3>
      </CLink>
    </CHeader>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CourseList from "@/containers/TFW/LandingPage/StaticCourseList";
import PartnerList from "@/containers/TFW/LandingPage/PartnerList";
export default {
  name: "TFWHeader",
  data() {
    return {
      isMobile: false,
      isSmall: false,
      collapsed: false,
    };
  },
  computed: {
    isStmUrl() {
        return this.$route?.path.includes("/stm") || false;
      }
  },
  components: {
    CourseList,
    PartnerList,
  },
  updated() {
    if (
      window.matchMedia("only screen and (max-width: 359px)").matches &&
      this.$router.currentRoute.path !== "/"
    )
      this.isSmall = true;
    if (
      window.matchMedia("(min-width: 992px) and (max-width: 1250px)").matches &&
      this.$router.currentRoute.path !== "/"
    )
      this.isSmall = true;
    let isMobile =
      (window.matchMedia("only screen and (max-width: 540px)").matches &&
        this.$router.currentRoute.path !== "/") ||
      (window.matchMedia("only screen and (max-width: 1250px)").matches &&
        this.$router.currentRoute.path !== "/");
    this.isMobile = isMobile;
  },
  methods: {
    ...mapActions(["fetchOrgDetailsByAcronym"]),
    scrollMeTo(refName) {
      let element = document.getElementById(refName);
      if (!element) {
        this.$router.push("/tfw").then((res) => {
          this.scrollMeTo(refName);
        });
        return;
      }
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    showHide(ref, isShow = false) {
      this.$refs[ref].visible = isShow;
    },

  },
  mounted() {
    this.fetchOrgDetailsByAcronym();
  },
};
</script>
